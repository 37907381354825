// general
import dayjs from 'dayjs';
import { useMediaQuery } from 'react-responsive'
import { useLocation } from 'react-router-dom';

// material ui
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

// components
import { CustomColorScheme } from './CustomTheme';


//////////////////////////////

export default function Copyright() {

  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' })
  const location = useLocation();

  const uptakeblueUrl = 'https://d3rqy5efbrvoqx.cloudfront.net';
  const dateRange = dayjs().year() === 2024
    ? "2024"
    : `2024 - ${dayjs().format('YYYY')}`

  return (
    <Stack
      textAlign='center'
      marginTop={2}
      color={CustomColorScheme['darkestBrown']}
    >
      <Typography>
        Copyright {dateRange}
        <Link
          target="_blank"
          href={uptakeblueUrl}
          sx={{
            color: CustomColorScheme['darkestBrown'],
            paddingX: 1,
            textDecoration: 'none',
          }}
        >@</Link>
        Michael Rubin{<br />}All rights reserved
      </Typography>
    </Stack>
  );
}
