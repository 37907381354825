// material ui
import Dialog from "@mui/material/Dialog";

// custom components
import AudiobookContent from '../AudiobookContent';



export default function AudiobookDialog(props) {
  const {
    dialogOpen,
    onClose,
  } = props;

  const handleDialogClose = () => {
    onClose();
  }

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleDialogClose}
      maxWidth="md"
    >
      <AudiobookContent
        onClose={handleDialogClose}
      />
    </Dialog>)
}

