// general
import '../App.css';
import { useState, useEffect, useContext } from 'react';
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

// material ui
import Tooltip from '@mui/material/Tooltip';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

// icons
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';


// custom components
import { CustomColorScheme } from './CustomTheme';
import AudiobookDialog from './dialogs/AudiobookDialog';
import { AudiobookCardContext } from './AllContext';
import AudiobookSummary from './AudiobookSummary';
import CustomMenuItem from './CustomMenuItem';
import DeleteConfirmationDialog from './dialogs/DeleteConfirmationDialog';



/////////////////////////


export default function AudiobookCardAuthor(props) {
  const {
    audiobook,
  } = props;


  // constants ////////////

  const {
    author,
    getAuthor,
    setContentAudiobook,
    setReturnPath,
    deleteAudiobook,
    updateAudiobook,
  } = useContext(AudiobookCardContext)

  const [menuAnchorElement, setMenuAnchorElement] = useState(null);
  const [cardAudiobook, setCardAudiobook] = useState({ audiobookId: 0 });
  const [audiobookDialogOpen, setAudiobookDialogOpen] = useState(false);
  const [openDeletionDialog, setOpenDeletionDialog] = useState(false);

  const [cardTitle, setCardTitle] = useState("");
  const [localAuthor, setLocalAuthor] = useState(null);

  const navigate = useNavigate();
  const menuOpen = Boolean(menuAnchorElement);
  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' })

  // useEffect ////////////

  useEffect(() => {
    if (audiobook) {
      setCardAudiobook(
        {
          audiobookId: audiobook.audiobookId,
          authorId: audiobook.authorId,
          authorLast: audiobook.authorLast,
          authorFirst: audiobook.authorFirst,
          series: audiobook.series,
          titleDisplay: audiobook.titleDisplay,
          number: audiobook.number,
          title: audiobook.title,
          note: audiobook.note,
          date: audiobook.date,
          isCurrent: audiobook.isCurrent,
          fileCount: audiobook.fileCount,
          isDisliked: audiobook.isDisliked,
          yearPublished: audiobook.yearPublished,
          readDate: audiobook.readDate,
          isRead: audiobook.isRead
        }
      );
      getAuthor(audiobook.authorId)
      setReturnPath('/author')
    }

  }, [audiobook]);

  useEffect(() => {
    if (cardAudiobook && cardAudiobook.title) {
      setCardTitle(cardAudiobook.title + (cardAudiobook.yearPublished ? " (" + cardAudiobook.yearPublished + ")" : ""))
    }
  }, [cardAudiobook]);

  useEffect(() => {
    if (author && !localAuthor) {
      setLocalAuthor(author)
    }
  }, [author])

  // event handlers /////////

  const handleDialogOpen = () => {
    setMenuAnchorElement(null)
    setContentAudiobook(cardAudiobook)
    if (isMobile) {
      navigate('/audiobookdetail');
    } else {
      setAudiobookDialogOpen(true);
    }
  }

  const handleShowMenu = (event) => {
    event.preventDefault();
    setMenuAnchorElement(event.currentTarget)
  }

  const handleBookDeleteClick = () => {
    deleteAudiobook(audiobook.audiobookId)
  }

  const handleOpenDeletionDialog = () => {
    setMenuAnchorElement(null);
    setOpenDeletionDialog(true);
  }

  const handleIsReadChange = () => {
    setMenuAnchorElement(null);
    audiobook.readDate = audiobook.readDate ? null : dayjs().format("YYYY-MM-DD");
    updateAudiobook(audiobook);
  }

  // render ///////////////

  return (
    <>
      <TableRow
        key={audiobook.audiobookId}
        sx={{
          '&:last-child td, &:last-child th': {
            border: 0,
          },
          backgroundColor: audiobook.isCurrent
            ? CustomColorScheme['lightYellow']
            : audiobook.isRead ? CustomColorScheme['offwhite'] : 'inherit',
        }}
      >
        <>
          <TableCell
            width={85}
          >
            <Typography
              sx={{
                whiteSpace: 'nowrap',
              }}
            >
              {audiobook.seriesAndNumber}
            </Typography>
          </TableCell>
          <TableCell
            sx={{
              padding: 0,
            }}
          >
            <Stack
              direction='row'
            >
              {
                isMobile
                  ?
                  <AudiobookSummary
                    audiobook={audiobook}
                    handleDialogOpen={handleDialogOpen}
                  />

                  :
                  <Tooltip
                    title={
                      <AudiobookSummary
                        audiobook={audiobook}
                      />}
                    arrow
                  >
                    <Typography
                      sx={{
                        paddingY: 1,
                        cursor: 'pointer',
                        '&:hover': {
                          textDecoration: 'underline',
                        }
                      }}
                      onClick={handleDialogOpen}
                      onContextMenu={isMobile ? null : handleShowMenu}
                    >
                      {cardTitle}
                    </Typography>
                  </Tooltip>
              }
              <Box
                sx={{
                  display: 'flex',
                  flexGrow: 1,
                  justifyContent: 'end',
                  alignItems: 'start'
                }}
                onContextMenu={isMobile ? null : handleShowMenu}
              >
                {
                  isMobile &&
                  <IconButton
                    onClick={(e) => setMenuAnchorElement(e.currentTarget)}
                  >
                    <MoreHorizIcon />
                  </IconButton>
                }
              </Box>
            </Stack>
          </TableCell>
        </>
      </TableRow>

      {/**** non-visible elements ****/}
      <AudiobookDialog
        dialogOpen={audiobookDialogOpen}
        onClose={() => setAudiobookDialogOpen(false)}
      />
      <Menu
        anchorEl={menuAnchorElement}
        open={menuOpen}
        onClose={() => setMenuAnchorElement(null)}
      >
        <CustomMenuItem
          title="Edit Audiobook"
          targetFunction={handleDialogOpen}
          targetIcon={<EditIcon />}
        />
        <Divider />
        <CustomMenuItem
          title="Delete Audiobook"
          targetFunction={handleOpenDeletionDialog}
          targetIcon={<DeleteIcon />}
        />
        <CustomMenuItem
          title={audiobook.isRead ? "Mark as Unread" : "Mark as Read"}
          targetFunction={handleIsReadChange}
          targetIcon={audiobook.isRead ? <CloseIcon /> : <CheckIcon />}
        />
      </Menu>

      <DeleteConfirmationDialog
        elementType="Audiobook"
        message={<>Delete audiobook:<br />"{audiobook.title}"?</>}
        dialogOpen={openDeletionDialog}
        onClose={() => setOpenDeletionDialog(false)}
        onDelete={handleBookDeleteClick}
      />

    </>
  )
}