// general
import { useState, useEffect, useContext } from 'react';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';

// material ui
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';


// custom components
import { CustomColorScheme, AudiobookTheme } from './CustomTheme';
import { AudiobookDialogContext } from './AllContext';
import DeleteConfirmationDialog from './dialogs/DeleteConfirmationDialog';


export default function AudiobookContent(props) {
  const {
    onClose,
  } = props;

  const {
    contentAudiobook,
    setContentAudiobook,
    createAudiobook,
    updateAudiobook,
    deleteAudiobook,
    authorList,
  } = useContext(AudiobookDialogContext);


  // constants /////////////

  const [authorId, setAuthorId] = useState(0);
  const [audiobookId, setAudiobookId] = useState(0);
  const [date, setDate] = useState('');
  const [note, setNote] = useState('');
  const [title, setTitle] = useState('');
  const [series, setSeries] = useState('');
  const [number, setNumber] = useState('');
  const [isDisliked, setIsDisliked] = useState(false);
  const [isCurrent, setIsCurrent] = useState(false);
  const [isReading, setIsReading] = useState(false);
  const [readDate, setReadDate] = useState();
  const [fileCount, setFileCount] = useState();
  const [originalReadDate, setOriginalReadDate] = useState();
  const [openDeletionDialog, setOpenDeletionDialog] = useState(false);
  const [isAuthorError, setIsAuthorError] = useState(false);
  const [isTitleError, setIsTitleError] = useState(false);
  const [yearPublished, setYearPublished] = useState('----');

  const isRead = Boolean(readDate);
  const isDetailpage = useLocation().pathname === '/audiobookdetail';

  const seriesNumbers =
    Array.from({ length: 60 }, (value, idx) => idx + 1).reduce((res, n) => {
      res.push({ value: n, display: n <= 9 ? "0" + n : n.toString() });
      return res;
    }, [])

  const publicationYears =
    Array.from({ length: dayjs().year() - 1930 }, (value, idx) => idx).reduce((res, n) => {
      res.push(dayjs().year() - n);
      return res;
    }, [])

  const authorSearchList =
    authorList &&
    authorList.length > 0 &&
    authorList.reduce((res, a) => {
      res.push({ label: a.last + ', ' + a.first, id: a.authorId });
      return res;
    }, [])

  // useEffect /////////////

  useEffect(() => {
    setIsAuthorError(false);
    setIsTitleError(false);
    if (contentAudiobook) {
      setAudiobookId(contentAudiobook.audiobookId ? contentAudiobook.audiobookId : '');
      setAuthorId(contentAudiobook.authorId ? contentAudiobook.authorId : 0);
      setDate(contentAudiobook.date ? contentAudiobook.date : '');
      setNote(contentAudiobook.note ? contentAudiobook.note : '');
      setTitle(contentAudiobook.title ? contentAudiobook.title : '');
      setSeries(contentAudiobook.series ? contentAudiobook.series : '');
      setNumber(contentAudiobook.number ? contentAudiobook.number : '');
      setIsDisliked(Boolean(contentAudiobook.isDisliked));
      setIsCurrent(contentAudiobook.isCurrent ? contentAudiobook.isCurrent !== null : false);
      setIsReading(contentAudiobook.isReading);
      setReadDate(contentAudiobook.readDate);
      setOriginalReadDate(contentAudiobook.readDate);
      setYearPublished(contentAudiobook.yearPublished);
      setFileCount(contentAudiobook.fileCount);
    }

  }, [contentAudiobook]);


  // event handlers ////////

  const handleSaveClick = () => {
    if (!title || (authorId < 1)) {
      if (authorId < 1) {
        setIsAuthorError(true)
      }
      if (!title) {
        setIsTitleError(true)
      }
    } else {
      let rDate = readDate & originalReadDate ? originalReadDate : readDate;
      if (!rDate && isDisliked) {
        rDate = dayjs().format("YYYY-MM-DD");
      }
      let audiobookObj = {
        audiobookId: contentAudiobook.audiobookId,
        authorFirst: contentAudiobook.authorFirst,
        authorId: authorId,
        authorLast: contentAudiobook.authorLast,
        date: contentAudiobook.date,
        isCurrent: isCurrent,
        isReading: isReading,
        isDisliked: isDisliked,
        isRead: isRead,
        note: note ? note : null,
        number: number,
        readDate: rDate,
        title: title,
        series: series,
        yearPublished: yearPublished,
        fileCount: fileCount,
      };
      if (audiobookId) {
        updateAudiobook(audiobookObj);
      } else {
        createAudiobook(audiobookObj);
      }
      onClose();
    }
  }

  const handleAuthorChange = (event) => {
    let authId = event.target.value;
    setIsAuthorError(authId < 1);
    setAuthorId(authId);
  }

  const handleTitleChange = (event) => {
    let t = event.currentTarget.value;
    setIsTitleError(!t);
    setTitle(t)
  }

  const handleBookDeleteClick = () => {
    deleteAudiobook(audiobookId)
    onClose();
  }

  const handleIsReadChange = (event) => {
    if (event.target.checked) {
      setReadDate(dayjs().format("YYYY-MM-DD"));
    } else {
      setReadDate(null);
    }
  }


  const handleIsReadingNowChange = (event) => {
    setIsReading(event.target.checked)
  }


  // render ////////////////

  return (
    <ThemeProvider theme={AudiobookTheme}>
      <Box
        sx={{
          backgroundColor: CustomColorScheme[isDetailpage ? 'mediumBrown' : 'greenBrown'],
          color: CustomColorScheme[isDetailpage ? 'black' : 'white'],
          paddingY: isDetailpage ? 1 : 1.5,
          paddingX: 2.5,
          fontSize: isDetailpage ? 24 : 18,

        }}
      >
        {audiobookId ? "Edit Audiobook" : "Create an Audiobook"}
      </Box>

      <Box
        sx={{
          padding: 2,
          backgroundColor: CustomColorScheme['lightTan'],
        }}
      >
        <Stack
          spacing={2}
        >
          {
            authorSearchList &&
            <FormControl error={isAuthorError} >
              <Select
                label='Author'
                variant='outlined'
                required
                error={isAuthorError}
                value={authorId ? authorId : 0}
                onChange={handleAuthorChange}
                sx={{
                  backgroundColor: CustomColorScheme['white'],
                  width: isDetailpage ? 'inherit' : 250,
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none'
                  },
                  '&.Mui-error': {
                    color: 'red',
                    border: 1,
                  },
                  '& .MuiSvgIcon-root': {
                    color: isAuthorError ? 'red' : 'inherit',
                  }
                }}
                MenuProps={{
                  sx: {
                    "&& .Mui-selected": {
                      backgroundColor: CustomColorScheme['transparentOrange40'],
                    },
                  },
                }}
              >
                <MenuItem
                  id="menuitem-0"
                  key={0}
                  value={0}
                >
                  select an author
                </MenuItem>
                {
                  authorSearchList && authorSearchList.map((author) => {
                    return (
                      <MenuItem
                        id={`menuitem-${author.id}`}
                        key={author.id}
                        value={author.id}
                      >
                        {author.label}
                      </MenuItem>
                    )
                  })

                }
              </Select>
              {
                isAuthorError &&
                <FormHelperText>Author is required</FormHelperText>
              }
            </FormControl>
          }
          <FormControl error={isTitleError} >
            <TextField
              value={title}
              onChange={handleTitleChange}
              label="Title"
              variant='standard'
              required
              error={isTitleError}
              padding={1}
              InputLabelProps={{
                shrink: true,
                sx: {
                  marginLeft: 1,
                  marginTop: 0.5,
                }
              }}
              sx={{
                backgroundColor: CustomColorScheme['white'],
                padding: 1,
              }}
            />
            {
              isTitleError &&
              <FormHelperText>Title is required</FormHelperText>
            }
          </FormControl>
          <Stack
            direction='row'
            spacing={1}
          >
            <TextField
              value={series}
              onChange={(e) => setSeries(e.currentTarget.value)}
              label='Series'
              variant='standard'
              InputLabelProps={{
                shrink: true,
                sx: {
                  marginLeft: 1,
                  marginTop: 0.5,
                },
              }}
              sx={{
                backgroundColor: CustomColorScheme['white'],
                padding: 1,
                marginTop: 2,
              }}
            />
            <TextField
              value={number}
              onChange={(e) => setNumber(e.currentTarget.value)}
              label='Number'
              variant='standard'
              InputLabelProps={{
                shrink: true,
                sx: {
                  marginLeft: 1,
                  marginTop: 0.5,

                }
              }}
              sx={{
                backgroundColor: CustomColorScheme['white'],
                padding: 1,
                marginTop: 2,
                width: 65,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            />
            <Select
              label='Year'
              variant='outlined'
              required
              value={yearPublished}
              onChange={(e) => setYearPublished(e.target.value > 0 ? e.target.value : null)}
              sx={{
                backgroundColor: CustomColorScheme['white'],
                width: 85,
                padding: 0,
                textAlign: 'end',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none'
                },
                '&.Mui-error': {
                  color: 'red',
                  border: 1,
                },
                '& .MuiSvgIcon-root': {
                  color: isAuthorError ? 'red' : 'inherit',
                },
              }}
              MenuProps={{
                sx: {
                  "&& .Mui-selected": {
                    backgroundColor: CustomColorScheme['transparentOrange40'],
                  },
                },
              }}
            >
              <MenuItem
                key={0}
                value={-1}
              >
                ----
              </MenuItem>
              {
                publicationYears.map((n) => {
                  return (
                    <MenuItem
                      key={n}
                      value={n}
                    >
                      {n}
                    </MenuItem>
                  )
                })
              }
            </Select>

          </Stack>
          <TextField
            value={note}
            onChange={(e) => setNote(e.currentTarget.value)}
            label='Note'
            variant='standard'
            multiline
            minRows={2}
            InputLabelProps={{
              shrink: true,
              sx: {
                marginLeft: 1,
                marginTop: 0.5,
              }
            }}
            sx={{
              backgroundColor: CustomColorScheme['white'],
              padding: 1,
              marginBottom: 1,
            }}
          />
          {/* summary */}
          <Box>
            <Typography>
              Date Acquired: {dayjs(date).format("MMM D, YYYY")}
            </Typography>
            {
              readDate &&
              <Typography>
                Date Read: {readDate ? dayjs(readDate).format("MMM D, YYYY") : null}
              </Typography>
            }
            {
              contentAudiobook &&
              contentAudiobook.fileCount &&
              <Typography>
                File Count: {contentAudiobook.fileCount}
              </Typography>
            }
          </Box>

          {/* checklist bar */}
          <Stack
            direction='row'
            spacing={0}
            sx={{
              paddingY: 1,
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    paddingY: 0,
                  }}
                  checked={isCurrent}
                  onChange={(e) => setIsCurrent(e.target.checked)}
                />
              }
              label='Current'
            />
            {
              isCurrent &&
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      paddingY: 0,
                    }}
                    checked={isReading}
                    onChange={handleIsReadingNowChange}
                  />
                }
                label='Now Reading'
              />

            }
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    paddingY: 0,
                  }}
                  checked={isRead}
                  onChange={handleIsReadChange}
                />
              }
              label='Read'
            />
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    paddingY: 0,
                  }}
                  checked={isDisliked}
                  onChange={(e) => setIsDisliked(e.target.checked)}
                />
              }
              label='Disliked'
            />
          </Stack>
        </Stack>
      </Box>

      {/* button bar */}
      <Stack
        direction='row'
        spacing={3}
        sx={{
          justifyContent: 'center',
          backgroundColor: CustomColorScheme['mediumBrown'],
          paddingY: 1.5,
        }}
      >
        <Typography
          sx={{
            fontSize: isDetailpage ? 20 : 18,
            cursor: 'pointer',
            '&:hover': {
              fontWeight: 'bold',
            }
          }}
          onClick={handleSaveClick}
        >Save
        </Typography>
        {
          contentAudiobook.title &&
          <Typography
            sx={{
              fontSize: isDetailpage ? 20 : 18,
              cursor: 'pointer',
              '&:hover': {
                color: CustomColorScheme['red'],
              }
            }}
            onClick={() => setOpenDeletionDialog(true)}
          >
            Delete
          </Typography>
        }
        <Typography
          sx={{
            fontSize: isDetailpage ? 20 : 18,
            cursor: 'pointer',
          }}
          onClick={() => onClose()}
        >
          Return
        </Typography>
      </Stack>

      {/**** non-visible elements ****/}
      <DeleteConfirmationDialog
        elementType="Audiobook"
        message={<>Delete audiobook:<br />"{title}"?</>}
        dialogOpen={openDeletionDialog}
        onClose={() => setOpenDeletionDialog(false)}
        onDelete={handleBookDeleteClick}
      />
    </ThemeProvider>
  )
}
