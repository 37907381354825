// general
import { useNavigate } from 'react-router-dom';

// material ui
import Stack from '@mui/material/Stack';

// custom components
import AudiobookContent from '../components/AudiobookContent';
import AppBar from '../components/Appbar';

export default function AudiobookDetail(props) {
  const {
    returnPath
  } = props;


  // event handlers /////////

  const handleClose = () => {
    console.log("handleClose returnPath", returnPath)
    navigate(returnPath);
  }

  // constants //////////////

  const navigate = useNavigate();

  return (
    <Stack
      padding={.5}
      spacing={.5}
    >
      <AppBar />
      <AudiobookContent
        onClose={handleClose}
      />
    </Stack>
  )

}