// general
import '../App.css';
import { useState, useEffect, useContext } from 'react';
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';


// material ui
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';

// icons
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';

// custom components
import { CustomColorScheme } from './CustomTheme';
import AudiobookDialog from './dialogs/AudiobookDialog';
import { AudiobookCardContext } from './AllContext';
import AudiobookSummary from './AudiobookSummary';
import CustomMenuItem from './CustomMenuItem';
import DeleteConfirmationDialog from './dialogs/DeleteConfirmationDialog';

/////////////////////////


export default function AudiobookCardCurrent(props) {
  const {
    audiobook,
    selectedAudiobooks,
    handleChecked,
    allowSelect,
  } = props;


  // constants ////////////

  const {
    author,
    getAuthor,
    setSelectedAuthor,
    setContentAudiobook,
    setReturnPath,
    setPage,
    authorList,
    deleteAudiobook,
    updateAudiobook,
  } = useContext(AudiobookCardContext)

  const [menuAnchorElement, setMenuAnchorElement] = useState(null);
  const [cardAudiobook, setCardAudiobook] = useState({ audiobookId: 0 });
  const [audiobookDialogOpen, setAudiobookDialogOpen] = useState(false);
  const [openDeletionDialog, setOpenDeletionDialog] = useState(false);

  const [localAuthorName, setLocalAuthorName] = useState(null);
  const [isSelected, setIsSelected] = useState(false)

  const menuOpen = Boolean(menuAnchorElement);
  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' })
  const navigate = useNavigate();

  // useEffect ////////////

  useEffect(() => {
    if (audiobook) {
      setCardAudiobook(
        {
          audiobookId: audiobook.audiobookId,
          authorId: audiobook.authorId,
          authorLast: audiobook.authorLast,
          authorFirst: audiobook.authorFirst,
          series: audiobook.series,
          titleDisplay: audiobook.titleDisplay,
          number: audiobook.number,
          title: audiobook.title,
          note: audiobook.note,
          date: audiobook.date,
          isCurrent: audiobook.isCurrent,
          fileCount: audiobook.fileCount,
          isDisliked: audiobook.isDisliked,
          isReading: audiobook.isReading,
          yearPublished: audiobook.yearPublished,
          readDate: audiobook.readDate,
          isRead: audiobook.isRead
        }
      );
      getAuthor(audiobook.authorId)
    }
    setReturnPath('/')
  }, [audiobook]);

  useEffect(() => {
    if (cardAudiobook && cardAudiobook.titleDisplay) {
      setLocalAuthorName(cardAudiobook.authorLast + ', ' + cardAudiobook.authorFirst)
    }
  }, [cardAudiobook]);

  useEffect(() => {
    if (author && !localAuthorName) {
      setLocalAuthorName(author.last + ', ' + author.first)
    }
  }, [author])

  useEffect(() => {
    if (audiobook && selectedAudiobooks != null && selectedAudiobooks != undefined) {
      setIsSelected(selectedAudiobooks.includes(audiobook.audiobookId));
    }
  }, [selectedAudiobooks])

  // event handlers ///////

  const handleAuthorNameClick = () => {
    let selection = authorList.find((a) => a.authorId === cardAudiobook.authorId);
    if (selection) {
      if (selection.authorId !== author.authorId) {
        setSelectedAuthor({
          label: selection.last + ', ' + selection.first,
          id: selection.authorId,
          books: selection.bookCount > 0
        });
        setPage(1);
      }
      navigate("/author")
    }
  }

  const handleDialogOpen = () => {
    setMenuAnchorElement(null)
    setContentAudiobook(cardAudiobook)
    if (isMobile) {
      navigate('/audiobookdetail');
    } else {
      setAudiobookDialogOpen(true);
    }
  }

  const handleShowMenu = (event) => {
    event.preventDefault();
    setMenuAnchorElement(event.currentTarget)
  }

  const handleBookDeleteClick = () => {
    deleteAudiobook(audiobook.audiobookId)
  }

  const handleOpenDeletionDialog = () => {
    setMenuAnchorElement(null);
    setOpenDeletionDialog(true);
  }

  const handleIsReadChange = () => {
    setMenuAnchorElement(null);
    audiobook.readDate = audiobook.readDate ? null : dayjs().format("YYYY-MM-DD");
    updateAudiobook(audiobook);
  }

  const handleIsReadingNowChange = () => {
    setMenuAnchorElement(null);
    audiobook.isReading = !audiobook.isReading;
    updateAudiobook(audiobook);
  }

  // render ///////////////

  return (
    <>
      <TableRow
        key={audiobook.audiobookId}
        sx={{
          backgroundColor: CustomColorScheme[audiobook.isReading ? 'offWhiteGreen' : audiobook.isRead ? 'offwhite' : 'white'],
        }}
      >
        {
          isMobile
            ?
            <>
              <TableCell
                sx={{
                  padding: 0,
                  paddingLeft: isMobile ? 0 : 1,
                  margin: 0,
                  width: 10,
                }}
              >
                {
                  allowSelect &&
                  <Checkbox
                    size='small'
                    checked={isSelected}
                    sx={{
                      color: CustomColorScheme['darkBrown'],
                      '&.Mui-checked': {
                        color: CustomColorScheme['darkBrown'],
                      },
                    }}
                    onChange={(e) => handleChecked(audiobook.audiobookId)}
                  />
                }
              </TableCell>
              <TableCell
                width={55}
                sx={{
                  padding: 0,
                  paddingLeft: 1,
                }}
              >
                <Typography
                  variant='body1'
                  component='div'
                  sx={{
                    fontSize: 16,
                    cursor: 'pointer',
                    '&:hover': {
                      textDecoration: 'underline',
                    }
                  }}
                  onClick={handleAuthorNameClick}
                >
                  {
                    localAuthorName &&
                    <>
                      <div>{localAuthorName.split(',')[0] + ','}</div>
                      <div>{localAuthorName.split(',')[1]}</div>
                    </>
                  }
                </Typography>

              </TableCell>
              <TableCell
                sx={{
                  padding: .5,
                  margin: 0,
                }}
              >
                <Stack
                  direction='row'
                >
                  <AudiobookSummary
                    audiobook={audiobook}
                    handleDialogOpen={handleDialogOpen}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      flexGrow: 1,
                      justifyContent: 'end',
                      alignItems: 'start'
                    }}
                  >
                    <IconButton
                      onClick={(e) => setMenuAnchorElement(e.currentTarget)}
                    >
                      <MoreHorizIcon />
                    </IconButton>
                  </Box>
                </Stack>
              </TableCell>
            </>
            :
            <>
              <TableCell
                sx={{
                  width: 25,
                  padding: 0,
                  paddingLeft: 1,
                }}
              >
                {
                  allowSelect &&
                  <Checkbox
                    size='small'
                    sx={{
                      paddingY: 0,
                      color: CustomColorScheme['darkBrown'],
                      '&.Mui-checked': {
                        color: CustomColorScheme['darkBrown'],
                      },
                    }}
                    checked={isSelected}
                    onChange={(e) => handleChecked(audiobook.audiobookId)}
                  />
                }
              </TableCell>
              <TableCell
                width={165}
                sx={{
                  borderRight: 1,
                  borderColor: CustomColorScheme['tan']
                }}
              >
                <Typography
                  variant='body1'
                  component='div'
                  sx={{
                    fontSize: 16,
                    cursor: 'pointer',
                    '&:hover': {
                      textDecoration: 'underline',
                    }
                  }}
                  onClick={handleAuthorNameClick}
                >
                  {localAuthorName}
                </Typography>
              </TableCell>
              <TableCell >
                <Stack
                  direction='row'
                >
                  <Tooltip
                    title={
                      <AudiobookSummary
                        audiobook={audiobook}
                        setMenuAnchorElement={setMenuAnchorElement}
                      />}
                    arrow
                  >
                    <Typography
                      sx={{
                        cursor: 'pointer',
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                      }}
                      onClick={handleDialogOpen}
                      onContextMenu={handleShowMenu}
                    >
                      {cardAudiobook.titleDisplay}
                    </Typography>
                  </Tooltip>
                  <Box
                    sx={{
                      flexGrow: 1,
                    }}
                    onContextMenu={handleShowMenu}
                  />
                </Stack>
              </TableCell>
            </>
        }
      </TableRow>

      {/**** non-visible elements ****/}
      <AudiobookDialog
        dialogOpen={audiobookDialogOpen}
        onClose={() => setAudiobookDialogOpen(false)}
      />

      <Menu
        anchorEl={menuAnchorElement}
        open={menuOpen}
        onClose={() => setMenuAnchorElement(null)}
      >
        <CustomMenuItem
          title="Edit Audiobook"
          targetFunction={handleDialogOpen}
          targetIcon={<EditIcon />}
        />
        <Divider />
        <CustomMenuItem
          title={audiobook.isRead ? "Mark as Unread" : "Mark as Read"}
          targetFunction={handleIsReadChange}
          targetIcon={audiobook.isRead ? <CloseIcon /> : <CheckIcon />}
        />
        <CustomMenuItem
          title={audiobook.isReading ? "Not Reading Now" : "Reading Now"}
          targetFunction={handleIsReadingNowChange}
          targetIcon={audiobook.isReading ? <AutoStoriesIcon /> : <AutoStoriesIcon />}
        />
        <Divider />
        <CustomMenuItem
          title="Delete Audiobook"
          targetFunction={handleOpenDeletionDialog}
          targetIcon={<DeleteIcon />}
        />
      </Menu>

      <DeleteConfirmationDialog
        elementType="Audiobook"
        message={<>Delete audiobook:<br />"{audiobook.title}"?</>}
        dialogOpen={openDeletionDialog}
        onClose={() => setOpenDeletionDialog(false)}
        onDelete={handleBookDeleteClick}
      />
    </>
  )
}