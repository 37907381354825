// general
import '../App.css';
import { useMediaQuery } from 'react-responsive'
import dayjs from 'dayjs';

// material ui
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// custom components



/////////////////////////

export default function AudiobookSummary(props) {
  const {
    audiobook,
    handleDialogOpen,
    setMenuAnchorElement,
  } = props;

  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' })

  if (audiobook.titleDisplay)
    return (
      isMobile
        ?
        <Stack
        >
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: 16,
              textDecoration: 'underline',
            }}
            onClick={handleDialogOpen}
            onContextMenu={(e) => setMenuAnchorElement ? setMenuAnchorElement(e.currentTarget) : null}
          >
            {audiobook.titleDisplay.split('(')[0].replace(")", "")}
          </Typography>
          {
            audiobook.series &&
            audiobook.number &&
            <Typography
              sx={{
                fontSize: 15,
              }}
            >
              {"Series: " + audiobook.titleDisplay.split('(')[1].replace(")", "")}
            </Typography>
          }
          {
            audiobook.yearPublished &&
            <Typography
              sx={{
                fontSize: 15,
              }}
            >
              {"Published: " + audiobook.yearPublished}
            </Typography>
          }
          {
            (audiobook.isCurrent || !audiobook.readDate) &&
            audiobook.fileCount &&
            <Typography
              sx={{
                fontSize: 15,
              }}
            >
              {"Files: " + audiobook.fileCount}
            </Typography>
          }
        </Stack>
        :
        <Box
          padding={.5}
          paddingBottom={0}
        >
          <Stack
            direction='row'
            paddingBottom={.5}
          >
            <Box>Date Acquired:</Box>
            <Box paddingLeft={1}>{dayjs(audiobook.date).format("MMM D, YYYY")}</Box>
          </Stack>

          {
            (audiobook.isCurrent || !audiobook.readDate) &&
            audiobook.fileCount &&
            <Stack
              direction='row'
              paddingBottom={.5}
            >
              <Box>File Count:</Box>
              <Box paddingLeft={1}>{audiobook.fileCount}</Box>
            </Stack>
          }

          {
            audiobook.yearPublished &&
            <Stack
              direction='row'
              paddingBottom={.5}
            >
              <Box>Year Published:</Box>
              <Box paddingLeft={1}>{audiobook.yearPublished}</Box>
            </Stack>
          }
          {
            audiobook.readDate &&
            <Stack
              direction='row'
              paddingBottom={.5}
            >
              <Box>Date Read:</Box>
              <Box paddingLeft={1}>{dayjs(audiobook.readDate).format("MMM D, YYYY")}</Box>
            </Stack>
          }
        </Box>
    );
}
