// general
import { useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'

// material ui
import MuiAppBar from '@mui/material/AppBar';
import Stack from '@mui/material/Stack';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

// icons
import HeadsetIcon from '@mui/icons-material/Headset';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import LogoutIcon from '@mui/icons-material/Logout';

// components
import { CustomColorScheme } from './CustomTheme';
import CustomMenuItem from './CustomMenuItem';
import { IconButton, Tooltip } from '@mui/material';
import { AuthContext } from '../AuthContext';
import AudiobookDialog from './dialogs/AudiobookDialog';
import AuthorDialog from './dialogs/AuthorDialog';
import { AppbarContext } from './AllContext';

//////////////////////////////

export default function AppBar(props) {

  const {
    selectedAuthor,
    setContentAudiobook,
    setReturnPath,
  } = useContext(AppbarContext);


  // constants //////////

  const [menuAnchorElement, setMenuAnchorElement] = useState(null);
  const [authorDialogOpen, setAuthorDialogOpen] = useState(false);
  const [audiobookDialogOpen, setAudiobookDialogOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const isHomepage = location.pathname === "/";
  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' })

  const menuOpen = Boolean(menuAnchorElement);
  const { signOut } = useContext(AuthContext)

  const emptyAuthor = {
    authorId: 0,
    last: '',
    first: '',
    note: ''
  }



  // event handlers /////////

  const handleNavigateHome = () => {
    setMenuAnchorElement(null);
    navigate("/");
  }

  const handleNavigateToAuthors = () => {
    setMenuAnchorElement(null);
    navigate("/author");
  }

  const handleLogout = () => {
    setMenuAnchorElement(null);
    signOut();
    navigate("/");
  };

  const handleAudiobookDialogOpen = () => {
    setMenuAnchorElement(null);
    let authorId = 0;
    if (!isHomepage) {
      authorId = selectedAuthor.id
    }
    let audiobookObj = {
      audiobookId: 0,
      authorId: authorId,
      date: '',
      isCurrent: false,
      isDisliked: false,
      isRead: false,
      note: null,
      number: null,
      readDate: null,
      title: '',
      yearPublished: null,
      series: '',
    }
    setReturnPath(location.pathname);
    setContentAudiobook(audiobookObj);
    if (isMobile) {
      navigate('/audiobookdetail');
    } else {
      setAudiobookDialogOpen(true);
    }
  }

  const handleAuthorDialogOpen = () => {
    setMenuAnchorElement(null);
    setAuthorDialogOpen(true);
  }

  // render /////////////////

  return (
    <>
      <MuiAppBar
        position='static'
        elevation={0}
        sx={{
          backgroundColor: CustomColorScheme['darkBlue'],
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          sx={{
            paddingY: 1.5,
            height: 35,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <IconButton
            onClick={(e) => setMenuAnchorElement(e.currentTarget)}
            sx={{
              padding: 0,
              color: CustomColorScheme['orange'],
              '&:hover': {
                color: CustomColorScheme['white'],
              },
              // border: 1,
            }}
          >
            <HeadsetIcon />
          </IconButton>
          <Tooltip
            title={isHomepage ? "Navigate to Authors page" : "Home page"}
          >
            <Typography
              variant='body1'
              component='div'
              fontSize={18}
              onClick={() => isHomepage ? null : navigate(isHomepage ? "/author" : "/")}
              sx={{
                cursor: isHomepage ? 'inherit' : 'pointer',
                '&:hover': {
                  color: isHomepage ? 'inherit' : CustomColorScheme['orange']
                }
              }}
            >Michael's Audiobooks
            </Typography>
          </Tooltip>
        </Stack>
      </MuiAppBar>

      {/**** non-visible elements ****/}
      <Menu
        anchorEl={menuAnchorElement}
        open={menuOpen}
        onClose={() => setMenuAnchorElement(null)}
      >
        <CustomMenuItem
          title="Current Audiobooks"
          targetFunction={handleNavigateHome}
          targetIcon={<HeadphonesIcon />}
        />
        <CustomMenuItem
          title="Audiobooks by Author"
          targetFunction={handleNavigateToAuthors}
          targetIcon={<PeopleAltIcon />}
        />
        {
          (location.pathname !== '/audiobookdetail') &&
          <>
            <Divider />
            <CustomMenuItem
              title="Create an Author"
              targetFunction={handleAuthorDialogOpen}
              targetIcon={null}
            />
            <CustomMenuItem
              title="Create an Audiobook"
              targetFunction={handleAudiobookDialogOpen}
              targetIcon={null}
            />
          </>
        }
        <Divider />
        <CustomMenuItem
          title="Log Out"
          targetFunction={handleLogout}
          targetIcon={<LogoutIcon />}
        />
      </Menu>
      <AudiobookDialog
        dialogOpen={audiobookDialogOpen}
        onClose={() => setAudiobookDialogOpen(false)}
      />
      <AuthorDialog
        author={emptyAuthor}
        dialogOpen={authorDialogOpen}
        onClose={() => setAuthorDialogOpen(false)}
      />
    </>
  );
}
