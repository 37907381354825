import { createContext } from 'react';

export const HomeContext = createContext();

export const AuthorContext = createContext();

export const AuthorDialogContext = createContext();

export const AudiobookDialogContext = createContext();

export const AudiobookCardContext = createContext();

export const AppbarContext = createContext();