// general
import '../App.css';
import { Routes, Route } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useLocation } from 'react-router-dom';

// material ui
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// pages
import Home from './Home';
import Author from './Author';
import Callback from './Callback';
import Login from './Login';
import AudiobookDetail from './AudiobookDetail';

// components
import { CustomColorScheme } from '../components/CustomTheme';
import Appbar from '../components/Appbar';
import RouteGuard from '../components/RouteGuard';
import Copyright from '../components/Copyright';


export default function Index(props) {

  const {
    returnPath
  } = props

  document.body.style.backgroundColor = CustomColorScheme['body'];
  const location = useLocation();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {
        (location.pathname !== '/audiobookdetail') &&
        <Appbar />
      }
      <Routes>
        <Route
          path='/'
          element={
            <RouteGuard>
              <Home />
            </RouteGuard>
          }
        />
        <Route
          path='/author'
          element={
            <RouteGuard>
              <Author />
            </RouteGuard>
          }
        />
        <Route
          path='/audiobookdetail'
          element={
            <RouteGuard>
              <AudiobookDetail
                returnPath={returnPath}
              />
            </RouteGuard>
          }
        />
        <Route
          path='/callback'
          element={
            <RouteGuard>
              <Callback />
            </RouteGuard>
          }
        />
        <Route
          path="login"
          element=
          {<Login />}
        />
      </Routes>
      <Copyright />
    </LocalizationProvider>
  );
}

