// general

// material ui
import Icon from '@mui/material/Icon';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';


export default function CustomMenuItem(props) {
  const {
    title,
    targetFunction,
    targetIcon,
  } = props;

  return (
    <MenuItem
      onClick={targetFunction}
    >
      <ListItemIcon>
        <Icon
          sx={{
            display: 'flex',
          }}
        >{targetIcon}</Icon>
      </ListItemIcon>
      <ListItemText>
        {title}
      </ListItemText>
    </MenuItem >
  )
}
