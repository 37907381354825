// general
import { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive'

// material ui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Dialog from "@mui/material/Dialog";
import { ThemeProvider } from '@mui/material/styles';

// custom components
import { CustomColorScheme, AudiobookTheme } from '../CustomTheme';

export default function AudiobookAddCurrentDialog(props) {
  const {
    dialogOpen,
    onClose,
    audiobookSearchList,
    setAudiobookIsCurrent,
  } = props;


  // constants //////////////

  const [selectedAudiobook, setSelectedAudiobook] = useState('');
  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' })

  useEffect(() => {
    setSelectedAudiobook(null);
  }, [])

  // event handlers /////////

  const handleSelectionChange = (event, selection) => {
    let id = selection ? selection.id : null;
    setSelectedAudiobook(selection);
  }

  const handleAddtoCurrent = () => {
    if (selectedAudiobook) {
      let audiobookObj = {
        audiobookId: selectedAudiobook.id,
        isCurrent: true
      }
      setAudiobookIsCurrent(audiobookObj);
      closeDialog();
    }
  }

  const closeDialog = () => {
    setSelectedAudiobook('');
    onClose();
  }

  // render /////////////////

  return (
    <Dialog
      open={dialogOpen}
      onClose={closeDialog}
      maxWidth="md"
    >
      <ThemeProvider theme={AudiobookTheme}>
        <Box
          sx={{
            backgroundColor: CustomColorScheme['greenBrown'],
            color: CustomColorScheme['white'],
            padding: 1,
            fontSize: 18,
            textAlign: 'center',
            width: isMobile ? 300 : 365,
          }}
        >
          Add Audiobook to Current List
        </Box>
        <Paper
          sx={{
            '&.MuiPaper-root': {
              padding: 2,
              fontSize: 18,
              backgroundColor: CustomColorScheme['lightTan'],
            }
          }}
        >
          <Typography
            paddingTop={1}
          >
            Select an Audiobook
          </Typography>
          <Autocomplete
            options={audiobookSearchList}
            renderInput={(params) =>
              <TextField
                {...params}
                variant='standard'
                label='Audiobook'
              />
            }
            value={selectedAudiobook}
            onChange={handleSelectionChange}
            variant='standard'
            sx={{
              padding: 1,
              marginBottom: 2,
              marginTop: .5,
              backgroundColor: CustomColorScheme['white'],
            }}
          />
          <Stack
            direction='row'
            spacing={4}
            justifyContent='end'
          >
            <Typography
              sx={{
                display: selectedAudiobook ? 'flex' : 'none',
                cursor: selectedAudiobook
                  ? 'pointer'
                  : 'inherit',
                '&:hover': {
                  textDecoration: selectedAudiobook ? 'underline' : 'none',
                  fontWeight: 'bold',
                }
              }}
              onClick={handleAddtoCurrent}
            >
              Add to Current List
            </Typography>
            <Typography
              sx={{
                cursor: selectedAudiobook
                  ? 'pointer'
                  : 'inherit',
                '&:hover': {
                  textDecoration: selectedAudiobook ? 'underline' : 'none',
                  fontWeight: 'bold',
                }
              }}
              onClick={closeDialog}
            >
              Close
            </Typography>
          </Stack>
        </Paper >
      </ThemeProvider>
    </Dialog>
  );
};


