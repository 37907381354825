// general
import { useMediaQuery } from 'react-responsive'

// material ui
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material//DialogTitle';

// custom components
import { CustomColorScheme } from '../CustomTheme';

export default function AudiobookRemoveCurrentDialog(props) {
  const {
    audiobooks,
    dialogOpen,
    onClose,
    onAccept,
  } = props;

  // contants ///////////////

  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' })

  // render /////////////////

  return (
    <Dialog
      open={dialogOpen}
      onClose={onClose}
      maxWidth='lg'
    >
      <Box
        sx={{
          color: 'black',
          backgroundColor: CustomColorScheme['white'],
          textAlign: 'center',
          width: isMobile ? 'inherit' : 500,
        }}
      >
        <DialogTitle
          id='confirm-dialog'
          sx={{
            '&.MuiDialogTitle-root': {
              backgroundColor: CustomColorScheme['greenBrown'],
              color: CustomColorScheme['white'],
              padding: 1,
            }
          }}
        >
          Remove Audiobooks
        </DialogTitle>
        <DialogContent
          sx={{
            '&.MuiDialogContent-root': {
              padding: 4,
              fontSize: 18,
            }
          }}
        >
          <Typography
            sx={{
              paddingBottom: 1,
            }}
          >
            Remove from Current Audiobooks?
          </Typography>
          {
            audiobooks &&
            audiobooks.map((audiobook, idx) => {
              return (
                <Typography
                  key={idx}
                  fontSize={17}
                >
                  {audiobook.title}
                </Typography>
              )
            })
          }
        </DialogContent>
        <DialogActions
          sx={{
            '&.MuiDialogActions-root': {
              backgroundColor: CustomColorScheme['lightTan'],
            }
          }}
        >
          <Stack
            direction='row'
            spacing={3}
            sx={{
              width: '100%',
              padding: 1,
              paddingTop: .5,
              justifyContent: 'center',
            }}
          >
            <Typography
              onClick={() => {
                onClose(false);
                onAccept();
              }}
              sx={{
                fontWeight: 'bold',
                fontSize: 18,
                color: CustomColorScheme['darkestBrown'],
                cursor: 'pointer',
                '&:hover': {
                  color: CustomColorScheme['darkGreen'],
                }
              }}
            >
              Remove
            </Typography>
            <Typography
              sx={{
                fontSize: 18,
                color: CustomColorScheme['darkestBrown'],
                cursor: 'pointer',
              }}
              onClick={onClose}
            >
              Cancel
            </Typography>
          </Stack>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
