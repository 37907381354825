// general
import '../App.css';
import { useState, useEffect, useContext } from 'react';
import { useMediaQuery } from 'react-responsive'

// material ui
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';


// custom components
import { CustomColorScheme } from '../components/CustomTheme';
import { HomeContext } from '../components/AllContext'
import AudiobookCardCurrent from '../components/AudiobookCardCurrent';
import AudiobookAddCurrentDialog from '../components/dialogs/AudiobookAddCurrentDialog';
import AudiobookRemoveCurrentDialog from '../components/dialogs/AudiobookRemoveCurrentDialog';

////////////////////////


export default function Home(props) {
  const { } = props;

  const {
    audiobookList,
    getAudiobooks,
    setAudiobookIsCurrent,
    statusMessage,
    setStatusMessage,
    updateAudiobookMultiple,
  } = useContext(HomeContext);


  // constants ///////////////

  const [sortParams, setSortParamsValues] = useState(
    ((localStorage.getItem("sortParameters") !== null) && (JSON.parse(localStorage.getItem("sortParameters")).direction) && (JSON.parse(localStorage.getItem("sortParameters")).sortkey))
      ? JSON.parse(localStorage.getItem("sortParameters"))
      : {
        sortkey: "author",
        direction: "asc"
      }
  )

  function setSortParams(paramsObject) {
    setSortParamsValues(paramsObject)
    localStorage.setItem("sortParameters", JSON.stringify(paramsObject))
  }

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [dialogOpenRemoveCurrent, setDialogOpenRemoveCurrent] = useState(false);
  const [dialogOpenAddToCurrent, setDialogOpenAddToCurrent] = useState(false);
  const [selectedAudiobooks, setSelectedAudiobooks] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [selectStatus, setSelectStatus] = useState('none');

  const allowSelect = selectStatus === "allowSelect";
  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' })

  const audiobookCurrentList =
    audiobookList &&
    audiobookList.filter((a) => a.isCurrent)

  const sortedAudiobooks =
    audiobookCurrentList &&
    audiobookCurrentList.sort(
      getComparator(
        sortParams && sortParams.sortkey ? sortParams.sortkey : "",
        sortParams && sortParams.sortkey && sortParams.sortkey === "title" ? "author" : "title",
        sortParams && sortParams.direction ? sortParams.direction : 'asc')
    );

  const selectedTitles =
    audiobookCurrentList &&
    audiobookCurrentList
      .filter((a) => selectedAudiobooks.includes(a.audiobookId));

  const audiobookSearchList =
    audiobookList &&
    audiobookList.reduce((res, a) => {
      if (a.titleSearch) {
        res.push({ id: a.audiobookId, label: a.titleSearch });
      }
      return res;
    }, [])

  const selectButtonEnabled = (selectStatus === "none") || (selectedAudiobooks.length > 0);

  // useEffect ///////////////

  useEffect(() => {
    getAudiobooks();
  }, []);

  useEffect(() => {
    if (statusMessage && statusMessage.status) {
      setSnackbarOpen(true);
      if (statusMessage.status === 'success') {
        setSelectedAudiobooks([])
      }
    }
  }, [statusMessage])


  // event handlers //////////


  const handleAllSelected = (event) => {
    let val = event.target.checked;
    setAllSelected(val);
    if (val) {
      let a = []
      audiobookCurrentList.map((audiobook) => {
        a.push(audiobook.audiobookId)
      })
      setSelectedAudiobooks(a)
    } else {
      setSelectedAudiobooks([])
    }
  }

  const handleChecked = (audiobookId) => {
    if (selectedAudiobooks.includes(audiobookId)) {
      setSelectedAudiobooks(selectedAudiobooks.filter(id => id !== audiobookId));
    } else {
      setSelectedAudiobooks([...selectedAudiobooks, audiobookId])
    }
  }

  const handleRemoveFromCurrent = () => {
    updateAudiobookMultiple(selectedAudiobooks);
  }

  const handleSelectMultiple = () => {
    if (selectStatus === "none") {
      setSelectStatus("allowSelect")
    } else {
      setSelectStatus("none")
      if (selectedAudiobooks.length > 0) {
        setDialogOpenRemoveCurrent(true);
      }
    }
  }

  const handleCloseRemoveCurrentDialog = () => {
    setSelectStatus("none");
    setDialogOpenRemoveCurrent(false);
    setAllSelected(false);
    setSelectedAudiobooks([]);
  }

  const handleOpenAddAudiobookDialog = () => {
    setDialogOpenAddToCurrent(true);
  }


  // functions //////////////


  function comparator(a, b, sortkey1, sortkey2) {
    let aVal = a[sortkey1] ? a[sortkey1] : "";
    let bVal = b[sortkey1] ? b[sortkey1] : "";
    let result = aVal.localeCompare(bVal)
    if (result === 0) {
      aVal = a[sortkey2] ? a[sortkey2] : "";
      bVal = b[sortkey2] ? b[sortkey2] : "";
      result = aVal.localeCompare(bVal)
    }
    return result
  }

  function getComparator(sortkey1, sortkey2, direction) {
    sortkey1 = sortkey1 === 'author' ? 'authorLast' : sortkey1;
    sortkey2 = sortkey2 === 'author' ? 'authorLast' : sortkey2;
    return direction === 'desc'
      ? (a, b) => -1 * comparator(a, b, sortkey1, sortkey2)
      : (a, b) => comparator(a, b, sortkey1, sortkey2)
  }


  // render //////////////////

  return (
    <div>
      <Container
        maxWidth={isMobile ? 'sm' : 'false'}
        sx={{
          width: isMobile ? 'inherit' : 700,
        }}
      >
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={isMobile ? 0 : 2}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 2,
            marginBottom: 1,
          }}
        >
          <Typography
            textAlign='center'
            fontSize={24}
          >
            Current Audiobooks
          </Typography>
          <Stack
            direction='row'
            spacing={1.5}
            sx={{
              paddingY: 1,
            }}
          >
            <Tooltip
              arrow
              title={
                <>
                  <div>Add an Audiobook</div>
                  <div>to the Current List</div>
                </>
              }
            >
              <Typography
                display='flex'
                alignItems='center'
                sx={{
                  width: 110,
                  justifyContent: 'center',
                  marginY: 2,
                  marginX: .25,
                  cursor: 'pointer',
                  backgroundColor: CustomColorScheme['tan'],
                  borderRadius: 7,
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
                onClick={handleOpenAddAudiobookDialog}
              >
                Add
              </Typography>
            </Tooltip>

            <Tooltip
              arrow
              title={
                <>
                  <div>Remove Selected Audiobooks</div>
                  <div>from the Current List</div>
                </>
              }
            >
              <Typography
                display='flex'
                alignItems='center'
                sx={{
                  width: 110,
                  justifyContent: 'center',
                  borderRadius: 7,
                  marginX: .25,
                  backgroundColor: CustomColorScheme['tan'],
                  cursor: selectButtonEnabled ? 'pointer' : 'inherit',
                  color: 'inherit',
                  '&:hover': {
                    textDecoration: selectButtonEnabled ? 'underline' : 'inherit',
                  }
                }}
                onClick={handleSelectMultiple}
              >
                {
                  allowSelect
                    ? selectedAudiobooks.length === 0 ? "End Select" : "Remove"
                    : "Select"
                }
              </Typography>
            </Tooltip>
          </Stack>
        </Stack>
        {
          sortedAudiobooks
            ?
            <TableContainer >
              <Table >
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: CustomColorScheme['darkTan'],
                      color: CustomColorScheme['white'],
                    }}
                  >
                    <TableCell
                      sx={{
                        padding: 0,
                        paddingLeft: isMobile ? 1 : 2,
                        margin: 0,
                        width: 30,
                      }}
                    >
                      {
                        allowSelect &&
                        <Checkbox
                          size='small'
                          sx={{
                            width: 20,
                            '&.Mui-checked': {
                              color: CustomColorScheme['darkGreenBrown'],
                            },
                          }}
                          checked={allSelected}
                          onChange={handleAllSelected}
                        />
                      }
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: 0,
                      }}
                    >
                      <TableSortLabel
                        id="author"
                        active={sortParams.sortkey === "author"}
                        direction={sortParams.direction}
                        onClick={() => setSortParams({
                          sortkey: "author",
                          direction: sortParams.sortkey === "author"
                            ? sortParams.direction === 'asc' ? 'desc' : 'asc'
                            : "asc"
                        })}
                      >
                        <Typography
                          sx={{
                            fontSize: 18,
                            fontWeight: 'bold',
                            paddingLeft: isMobile ? 0.5 : 2,
                          }}
                        >
                          Author
                        </Typography>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sx={{
                        paddingY: 1.5,
                        paddingLeft: isMobile ? .05 : 2,
                      }}
                    >
                      <TableSortLabel
                        id="title"
                        active={sortParams.sortkey === "title"}
                        direction={sortParams.direction}
                        onClick={() => setSortParams({
                          sortkey: "title",
                          direction: sortParams.sortkey === "title"
                            ? sortParams.direction === 'asc' ? 'desc' : 'asc'
                            : "asc"
                        })}
                      >
                        <Typography
                          sx={{
                            fontSize: 18,
                            fontWeight: 'bold',
                          }}
                        >
                          Title
                        </Typography>
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {
                    sortedAudiobooks.map((audiobook, idx) => {
                      return (
                        <AudiobookCardCurrent
                          key={idx}
                          audiobook={audiobook}
                          selectedAudiobooks={selectedAudiobooks}
                          handleChecked={handleChecked}
                          allowSelect={allowSelect}
                        />
                      )
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
            :
            <Box
              display='flex'
              flexGrow={1}
              height={350}
              justifyContent='center'
              alignItems='center'
              color={CustomColorScheme['white']}
            >
              <CircularProgress
                color='inherit'
              />
            </Box>
        }
      </Container>

      {/**** non-render elements *** */}
      <AudiobookRemoveCurrentDialog
        audiobooks={selectedTitles}
        dialogOpen={dialogOpenRemoveCurrent}
        // onClose={() => setDialogOpenRemoveCurrent(false)}
        onClose={handleCloseRemoveCurrentDialog}
        onAccept={handleRemoveFromCurrent}
      />

      <AudiobookAddCurrentDialog
        dialogOpen={dialogOpenAddToCurrent}
        onClose={() => setDialogOpenAddToCurrent(false)}
        audiobookSearchList={audiobookSearchList}
        setAudiobookIsCurrent={setAudiobookIsCurrent}
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={1250}
        onClose={() => {
          setSnackbarOpen(false);
          setStatusMessage({});
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }} >
        <Alert
          severity={statusMessage.status}
          variant="filled"
          elevation={6}>
          {statusMessage.message}
        </Alert>

      </Snackbar>
    </div >
  )
}