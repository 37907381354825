// general
import '../App.css';
import { useState, useEffect, useContext } from 'react';
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom';

// material ui
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Pagination from '@mui/material/Pagination';
import { ThemeProvider } from '@mui/material/styles';


// icons
import AddIcon from '@mui/icons-material/Add';

// custom components
import { CustomColorScheme, AudiobookTheme } from '../components/CustomTheme';
import { AuthorContext } from '../components/AllContext'
import AudiobookCardAuthor from '../components/AudiobookCardAuthor';
import AudiobookDialog from '../components/dialogs/AudiobookDialog';
import AuthorDialog from '../components/dialogs/AuthorDialog';

////////////////////////


export default function Author(props) {
  const { } = props;

  const {
    author,
    getAuthor,
    selectedAuthor,
    setSelectedAuthor,
    statusMessage,
    setStatusMessage,
    page,
    setPage,
    setReturnPath,
    setContentAudiobook,
    audiobookList,
    getAudiobooks,
    authorList,
  } = useContext(AuthorContext);


  // constants ///////////////

  const [sortParams, setSortParamsValues] = useState(
    ((localStorage.getItem("sortParametersAuthor") !== null) &&
      (JSON.parse(localStorage.getItem("sortParametersAuthor")).direction) &&
      (JSON.parse(localStorage.getItem("sortParametersAuthor")).sortkey))
      ? JSON.parse(localStorage.getItem("sortParametersAuthor"))
      : {
        sortkey: "seriesAndNumber",
        direction: "asc"
      }
  )

  function setSortParams(paramsObject) {
    setSortParamsValues(paramsObject)
    localStorage.setItem("sortParametersAuthor", JSON.stringify(paramsObject))
  }

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [audiobookDialogOpen, setAudiobookDialogOpen] = useState(false);

  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' })
  const navigate = useNavigate();

  const countPerPage = isMobile ? 8 : 12;

  // useEffect ///////////////

  useEffect(() => {
    if (!audiobookList) {
      getAudiobooks();
    }
  }, [])

  useEffect(() => {
    if (statusMessage && statusMessage.status) {
      setSnackbarOpen(true);
    }
  }, [statusMessage])


  const handleAuthorChange = (event, selection) => {
    setPage(1);
    if (selection && authorList) {
      let a = authorList.find((a) => a.authorId === selection.id);
      if (a) {
        setSelectedAuthor({
          id: selection.id,
          label: selection.label,
          books: a.bookCount > 0
        });
      } else {
        setSelectedAuthor(null);
      }
      getAuthor(selection.id);
    } else {
      setSelectedAuthor(null);
    }
  }


  const handlePaginationChange = (event, pge) => {
    if (audiobooksThisPage) {
      console.log(pge)
      setPage(pge);
    }
  };

  const handleSortByTitle = () => {
    setSortParams({
      sortkey: "title",
      direction: sortParams.sortkey === "title"
        ? sortParams.direction === 'asc' ? 'desc' : 'asc'
        : "asc"
    });
    setPage(1)
  }

  const handleSortBySeries = () => {
    setSortParams({
      sortkey: "seriesAndNumber",
      direction: sortParams.sortkey === "seriesAndNumber"
        ? sortParams.direction === 'asc' ? 'desc' : 'asc'
        : 'asc'
    });
    setPage(1);
  }

  const handleAudiobookDialogOpen = () => {
    let audiobookObj = {
      audiobookId: 0,
      authorId: selectedAuthor.id,
      date: '',
      isCurrent: false,
      isDisliked: false,
      isRead: false,
      note: null,
      number: null,
      readDate: null,
      title: '',
      series: '',
    }
    setReturnPath("/author");

    setContentAudiobook(audiobookObj);
    if (isMobile) {
      navigate('/audiobookdetail');
    } else {
      setAudiobookDialogOpen(true);
    }
  }


  const audiobookAuthorList = audiobookList && selectedAuthor && audiobookList.filter(a => a.authorId === selectedAuthor.id);

  const backdropSpinner =
    audiobookAuthorList &&
    audiobookAuthorList.length &&
    selectedAuthor &&
    selectedAuthor.books &&
    selectedAuthor && audiobookAuthorList[0].authorId !== selectedAuthor.id;

  const sortedAudiobooks =
    audiobookAuthorList &&
    audiobookAuthorList.sort(
      getComparator(
        sortParams && sortParams.sortkey ? sortParams.sortkey : "",
        sortParams && sortParams.sortkey && sortParams.sortkey === 'title' ? 'seriesAndNumber' : 'title',
        sortParams && sortParams.direction ? sortParams.direction : 'asc'));

  const pageCount = sortedAudiobooks && sortedAudiobooks.length > 0
    ? Math.ceil(sortedAudiobooks.length / countPerPage)
    : 0;

  const audiobooksThisPage = sortedAudiobooks
    ? sortedAudiobooks.slice((page - 1) * countPerPage, page * countPerPage)
    : null

  const authorSearchList =
    authorList &&
    authorList.length > 0 &&
    authorList.reduce((res, a) => {
      res.push({ label: a.last + ', ' + a.first, id: a.authorId });
      return res;
    }, [])


  function comparator(a, b, sortkey1, sortkey2) {
    let aVal = a[sortkey1] ? a[sortkey1].toString() : "";
    let bVal = b[sortkey1] ? b[sortkey1].toString() : "";
    let result = aVal.localeCompare(bVal)
    if (result === 0) {
      aVal = a[sortkey2] ? a[sortkey2].toString() : "";
      bVal = b[sortkey2] ? b[sortkey2].toString() : "";
      result = aVal.localeCompare(bVal)
    }
    return result
  }

  function getComparator(sortkey1, sortkey2, direction) {
    return direction === 'desc'
      ? (a, b) => -1 * comparator(a, b, sortkey1, sortkey2)
      : (a, b) => comparator(a, b, sortkey1, sortkey2)
  }


  // render //////////////////

  return (
    <ThemeProvider theme={AudiobookTheme}>
      <Container
        maxWidth={isMobile ? 'sm' : 'false'}
        sx={{
          width: isMobile ? 'inherit' : 700,
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            marginTop: 2,
            fontSize: 24,
            justifyContent: 'center',
          }}
        >
          Audiobooks By Author
        </Typography>
        <Stack
          display='flex'
          direction='row'
          alignItems='center'
          spacing={1}
          paddingY={2}
        >
          <Autocomplete
            options={authorSearchList ? authorSearchList : []}
            renderInput={(params) =>
              <TextField {...params}
                label='Author'
                sx={{
                  marginTop: 1,
                }}
              />
            }
            value={selectedAuthor}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            isOptionEqualToValue={(opData, value) => opData.label === value.label}
            onChange={handleAuthorChange}
            sx={{
              backgroundColor: CustomColorScheme['lightestTan'],
              width: 275,
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
              }
            }}
          />
          {
            selectedAuthor &&
            !backdropSpinner &&
            <Typography
              sx={{
                cursor: 'pointer',
                padding: 1,
                '&:hover': {
                  textDecoration: 'underline',
                }
              }}
              onClick={() => setDialogOpen(true)}
            >
              edit author
            </Typography>
          }
        </Stack>
        {
          sortedAudiobooks && sortedAudiobooks.length > 0
            ?
            <TableContainer component={Paper}>
              <Table >
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: CustomColorScheme['darkTan'],
                      color: CustomColorScheme['white'],
                    }}
                  >
                    <TableCell
                      sx={{
                        fontSize: 18,
                      }}
                    >
                      <TableSortLabel
                        id='seriesAndNumber'
                        active={sortParams.sortkey === 'seriesAndNumber'}
                        direction={sortParams.direction}
                        onClick={handleSortBySeries}
                      >
                        Series
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: 18,
                        paddingY: 0,
                      }}
                    >
                      <Stack
                        direction='row'
                      >
                        <TableSortLabel
                          id='title'
                          active={sortParams.sortkey === 'title'}
                          direction={sortParams.direction}
                          onClick={handleSortByTitle}
                        >
                          Title
                        </TableSortLabel>
                        <Box
                          flexGrow={1}
                        />
                        <IconButton
                          sx={{
                            margin: 0,
                          }}
                          onClick={handleAudiobookDialogOpen}
                        >
                          <AddIcon />
                        </IconButton>
                      </Stack>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    audiobooksThisPage.map((audiobook, idx) => {
                      return (
                        <AudiobookCardAuthor
                          key={idx}
                          audiobook={audiobook}
                        />
                      )
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
            :
            selectedAuthor && selectedAuthor.books && (
              <Box
                display='flex'
                flexGrow={1}
                height={350}
                justifyContent='center'
                alignItems='center'
                color={CustomColorScheme['white']}
              >
                <CircularProgress
                  color='inherit'
                />
              </Box>
            )
        }
        <Box
          display='flex'
          flexGrow={1}
          alignItems='center'
          justifyContent='center'
          paddingTop={2}
        >
          <Pagination
            size={isMobile ? 'small' : 'medium'}
            count={pageCount}
            page={page}
            onChange={handlePaginationChange}
          />
        </Box>

      </Container>

      {/**** non-render elements *** */}
      <Backdrop
        sx={{
          color: CustomColorScheme['white'],
          backgroundColor: "rgba(0, 0, 0, 0.4)",
        }}
        open={backdropSpinner}
      >
        <CircularProgress
          sx={{
            color: CustomColorScheme['orange']
          }}
        />
      </Backdrop>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={1250}
        onClose={() => {
          setSnackbarOpen(false);
          setStatusMessage({});
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }} >
        <Alert
          severity={statusMessage.status}
          variant="filled"
          elevation={6}>
          {statusMessage.message}
        </Alert>
      </Snackbar>
      <AuthorDialog
        author={author}
        dialogOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
      <AudiobookDialog
        dialogOpen={audiobookDialogOpen}
        onClose={() => setAudiobookDialogOpen(false)}
      />
    </ThemeProvider>
  )
}