// general
import { useState } from 'react';

// material ui
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material//DialogTitle';

// custom components
import { CustomColorScheme } from '../CustomTheme';

const DeleteConfirmationDialog = (props) => {
  const {
    elementType,
    message,
    dialogOpen,
    onClose,
    onDelete,
  } = props;

  return (
    <Dialog
      open={dialogOpen}
      maxWidth='lg'
      onClose={onClose}
      aria-labelledby='confirm-dialog'
    >
      <Box
        sx={{
          color: 'black',
          backgroundColor: CustomColorScheme['white'],
          textAlign: 'center',
        }}
      >
        <DialogTitle
          id='confirm-dialog'
          sx={{
            '&.MuiDialogTitle-root': {
              backgroundColor: CustomColorScheme['greenBrown'],
              color: CustomColorScheme['white'],
              padding: 1,
            }
          }}
        >
          {'Delete ' + elementType}
        </DialogTitle>
        <DialogContent
          sx={{
            '&.MuiDialogContent-root': {
              padding: 4,
              width: 300,
              fontSize: 18,
            }
          }}
        >{message}
        </DialogContent>
        <DialogActions
          sx={{
            '&.MuiDialogActions-root': {
              backgroundColor: CustomColorScheme['lightTan'],
            }
          }}
        >
          <Box
            flexGrow={1}
            padding={0}
            display='flex'
            justifyContent='flex-end'
            alignItems='flex-end'
          >
            <Stack
              direction='row'
              spacing={3}
              padding={1}
              paddingTop={.5}
              alignItems='flex-end'
              justifyContent='flex-start'
            >
              <Typography
                onClick={() => {
                  onClose(false);
                  onDelete();
                }}
                sx={{
                  fontWeight: 'bold',
                  fontSize: 18,
                  color: CustomColorScheme['darkestBrown'],
                  cursor: 'pointer',
                  '&:hover': {
                    color: CustomColorScheme['red'],
                  }
                }}
              >
                Delete
              </Typography>
              <Typography
                sx={{
                  fontSize: 18,
                  color: CustomColorScheme['darkestBrown'],
                  cursor: 'pointer',
                }}
                onClick={onClose}
              >
                Cancel
              </Typography>
            </Stack>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
