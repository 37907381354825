// general
import { useState, useEffect, useContext } from 'react';

// material ui
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { green, red } from '@mui/material/colors';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { ThemeProvider } from '@mui/material/styles';

// icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// custom components
import { CustomColorScheme, AudiobookTheme } from '../CustomTheme';
import { AuthorDialogContext } from '../AllContext'
import DeleteConfirmationDialog from './DeleteConfirmationDialog';


export default function AuthorDialog(props) {
  const {
    author,
    dialogOpen,
    onClose,
  } = props;

  const {
    createAuthor,
    updateAuthor,
    deleteAuthor,
  } = useContext(AuthorDialogContext);

  // constants /////////////

  const [authorId, setAuthorId] = useState(0);
  const [authorLast, setAuthorLast] = useState('');
  const [authorFirst, setAuthorFirst] = useState('');
  const [authorNote, setLocalAuthorNote] = useState('');

  const [openDeletionDialiog, setOpenDeletionDialiog] = useState(false);
  const [isLastnameError, setIsLastnameError] = useState(false);

  const BookCount = (props) => {
    const {
      bookCount,
      bookCountUnread,
      marginY,
    } = props;
    let count = bookCount ? bookCount : 0;
    let countUnread = bookCountUnread ? bookCountUnread : 0;
    return (
      <Stack
        direction='row'
        marginY={marginY}
        width='100%'
      >
        <div
          style={{ width: '50%', paddingLeft: 0, m: 0, }}
        >
          {'Total books: ' + count}
        </div>
        <div
          style={{ width: '50%' }}
        >
          {'Books read: ' + (count - countUnread)}
        </div>
      </Stack>

    )
  }


  // useEffect /////////////

  useEffect(() => {
    setIsLastnameError(false);
    if (author) {
      setAuthorId(author.authorId);
      setAuthorLast(author.last ? author.last : '');
      setAuthorFirst(author.first ? author.first : '');
      setLocalAuthorNote(author.note ? author.note : '');
    }
  }, [author]);


  // event handlers ////////

  const handleAuthorSaveClick = () => {
    if (!authorLast) {
      setIsLastnameError(true)
    } else {
      onClose();
      let authorObj = {
        authorId: authorId,
        last: authorLast,
        first: authorFirst,
        note: authorNote
      };
      if (authorId) {
        updateAuthor(authorObj);
      } else {
        createAuthor(authorObj);
      }
    }
  }

  const handleAuthorDeleteClick = () => {
    deleteAuthor(author.authorId)
    onClose();
  }

  const handleAuthorLastChange = (event) => {
    let t = event.currentTarget.value;
    setAuthorLast(t);
    setIsLastnameError(!t);
  }


  // render ////////////////

  return (
    author &&
    <>
      <Dialog
        open={dialogOpen}
        onClose={onClose}
        maxWidth="md"
      >
        <ThemeProvider theme={AudiobookTheme}>
          <Box
            backgroundColor={CustomColorScheme['greenBrown']}
            color={CustomColorScheme['white']}
            paddingY={1}
            paddingX={2.5}
          >
            {author.authorId ? "Edit Author" : "Create Author"}
          </Box>
          <Paper
            sx={{
              padding: 2,
              backgroundColor: CustomColorScheme['lightTan'],
              width: 300,
            }}
          >
            <Stack spacing={0}>
              <FormControl error={isLastnameError} >
                <TextField
                  value={authorLast}
                  onChange={handleAuthorLastChange}
                  label="Author Last"
                  variant='standard'
                  error={isLastnameError}
                  padding={1}
                  InputLabelProps={{
                    shrink: true,
                    sx: {
                      marginLeft: 1,
                      marginTop: 0.5,
                    },
                  }}
                  sx={{
                    backgroundColor: CustomColorScheme['white'],
                    padding: 1,
                  }}
                />
                {
                  isLastnameError &&
                  <FormHelperText>Last name is required</FormHelperText>
                }
              </FormControl>
              <TextField
                value={authorFirst}
                onChange={(e) => setAuthorFirst(e.currentTarget.value)}
                label='Author First'
                variant='standard'
                InputLabelProps={{
                  shrink: true,
                  sx: {
                    marginLeft: 1,
                    marginTop: 0.5,
                  },
                }}
                sx={{
                  backgroundColor: CustomColorScheme['white'],
                  padding: 1,
                  marginTop: 2,
                }}
              />
              {
                author.books && author.books.length
                  ? <>
                    <Accordion
                      sx={{
                        marginY: 2,
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        sx={{
                          '&.MuiAccordionSummary-root': {
                            paddingY: 0,
                            margin: 0,
                            height: 65,
                          }

                        }}>
                        <BookCount
                          bookCount={author.bookCount}
                          bookCountUnread={author.bookCountUnread}
                          marginY={2.5}
                        />

                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          backgroundColor: CustomColorScheme['tan'],
                        }}

                      >
                        {
                          author.books.map((book) => {
                            return (
                              <div key={book.bookId}>{book.title}</div>
                            )
                          })

                        }
                      </AccordionDetails>
                    </Accordion>
                  </>
                  :
                  <BookCount
                    bookCount={author.bookCount}
                    bookCountUnread={author.bookCountUnread}
                    marginY={2.5}
                  />
              }
              <TextField
                value={authorNote}
                onChange={(e) => setLocalAuthorNote(e.currentTarget.value)}
                label='Note'
                variant='standard'
                multiline
                minRows={2}
                InputLabelProps={{
                  shrink: true,
                  sx: {
                    marginLeft: 1,
                    marginTop: 0.5,
                  },
                }}
                sx={{
                  backgroundColor: CustomColorScheme['white'],
                  padding: 1,
                  marginBottom: 1,
                }}
              />
              <Stack
                direction='row'
                spacing={3}
                justifyContent='end'
              >
                <Typography
                  sx={{
                    borderColor: CustomColorScheme['darkestBrown'],
                    fontSize: 16,
                    cursor: 'pointer',
                    '&:hover': {
                      fontWeight: 'bold',
                    }
                  }}
                  onClick={handleAuthorSaveClick}
                >Save
                </Typography>
                {
                  author.last &&
                  <Typography
                    sx={{
                      fontSize: 16,
                      cursor: 'pointer',
                      '&:hover': {
                        color: CustomColorScheme['red'],
                      }
                    }}
                    disabled={author.bookCount > 0}
                    onClick={() => setOpenDeletionDialiog(true)}
                  >
                    Delete
                  </Typography>
                }
                <Typography
                  sx={{
                    fontSize: 16,
                    cursor: 'pointer',
                  }}
                  onClick={onClose}
                >
                  Return
                </Typography>
              </Stack>
            </Stack>
          </Paper>
        </ThemeProvider>
      </Dialog >

      {/**** non-visible elements ****/}
      <DeleteConfirmationDialog
        elementType="Author"
        message={'Delete author: "' + author.first + ' ' + author.last + '"?'}
        dialogOpen={openDeletionDialiog}
        onClose={() => setOpenDeletionDialiog(false)}
        onDelete={handleAuthorDeleteClick}
      />
    </>
  )
}
