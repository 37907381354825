// general
import './App.css';
import { useState } from 'react';
import axios from 'axios';


// components
import {
  HomeContext,
  AuthorContext,
  AuthorDialogContext,
  AudiobookDialogContext,
  AudiobookCardContext,
  AppbarContext,
} from './components/AllContext'

import Index from './pages/Index';
import * as auth from "./auth"


//////////////////////////////


export default function App() {


  // constants 
  const [audiobookList, setAudiobookList] = useState();
  const [authorList, setAuthorList] = useState();
  const [statusMessage, setStatusMessage] = useState({});
  const [author, setAuthor] = useState();
  const [contentAudiobook, setContentAudiobook] = useState({ audiobookId: 0 });
  const [returnPath, setReturnPath] = useState();
  const [page, setPage] = useState(1);


  const [selectedAuthor, setSelectedAuthorValue] = useState(
    localStorage.getItem("selectedAuthor") !== null
      ? JSON.parse(localStorage.getItem("selectedAuthor"))
      : null
  );

  function setSelectedAuthor(selection) {
    setSelectedAuthorValue(selection)
    localStorage.setItem("selectedAuthor", JSON.stringify(selection))
  }


  const [authorId, setAuthorId] = useState();

  const homeContext = {
    audiobookList,
    getAudiobooks,
    setAudiobookIsCurrent,
    statusMessage,
    setStatusMessage,
    updateAudiobookMultiple,
  }

  const authorContext = {
    author,
    getAuthor,
    selectedAuthor,
    setSelectedAuthor,
    statusMessage,
    setStatusMessage,
    page,
    setPage,
    setReturnPath,
    setContentAudiobook,
    audiobookList,
    getAudiobooks,
    authorList,
  }

  const authorDialogContext = {
    author,
    createAuthor,
    updateAuthor,
    deleteAuthor,
  }

  const audiobookDialogContext = {
    contentAudiobook,
    setContentAudiobook,
    createAudiobook,
    updateAudiobook,
    deleteAudiobook,
    returnPath,
    authorList,
  }

  const audiobookCardContext = {
    author,
    getAuthor,
    setSelectedAuthor,
    setContentAudiobook,
    setReturnPath,
    setPage,
    authorList,
    deleteAudiobook,
    updateAudiobook,
  }

  const appbarContext = {
    selectedAuthor,
    setContentAudiobook,
    setReturnPath,
  }


  // AUDIOBOOK //////////////////////
  async function getAudiobooks() {
    let url = `${process.env.REACT_APP_API_BASE_URL}/audiobook`;

    const session = await auth.getSession();
    const accessToken = session.accessToken.jwtToken

    let headers = {
      "headers": {
        "Authorization": `Bearer ${accessToken}`,
      }
    }
    await axios
      .get(
        url,
        headers,
      )
      .then((response) => {
        setAudiobookList(response.data);
        if (!authorList) {
          getAuthorList();
        }
      })
      .catch((error) => {
        console.log('API getAudiobooks() error', authorId ? authorId : "");
        console.log('API error url: ', url);
        processError(error);
      });
  }


  // PUT: updates a audiobook
  async function updateAudiobook(bookObj) {
    let url = `${process.env.REACT_APP_API_BASE_URL}/audiobook`;

    const session = await auth.getSession();
    const accessToken = session.accessToken.jwtToken
    let message;
    let headers = {
      "headers": {
        "Authorization": `Bearer ${accessToken}`,
      }
    }
    await axios
      .put(
        url,
        bookObj,
        headers,
      )
      .then((response) => {
        message = {
          status: "success",
          message: "Audiobook was successfully updated!"
        };
        getAudiobooks();
      })
      .catch((error) => {
        message = {
          status: "error",
          message: "Audiobook was not updated"
        };
        console.log('API updateAudiobook() error', bookObj);
        console.log('API error url: ', url);
        processError(error);
      }).finally(() => {
        setStatusMessage(message);
      });
  }

  // PUT: updates the status of multiple audiobook records
  async function updateAudiobookMultiple(audiobookIdArray) {
    let url = `${process.env.REACT_APP_API_BASE_URL}/audiobook/status/multiple`;

    const session = await auth.getSession();
    const accessToken = session.accessToken.jwtToken
    let message;
    let headers = {
      "headers": {
        "Authorization": `Bearer ${accessToken}`,
      }
    }
    let statusObj = {
      audiobookIdList: audiobookIdArray.join(', '),
      columnName: "is_current",
      value: false
    }
    await axios
      .put(
        url,
        statusObj,
        headers,
      )
      .then((response) => {
        message = {
          status: "success",
          message: "Audiobook(s) were successfully updated"
        };
        getAudiobooks();
      })
      .catch((error) => {
        message = {
          status: "error",
          message: "Audiobook(s) were not updated"
        };
        console.log('API updateAudiobookMultiple() error', audiobookIdArray);
        console.log('API error url: ', url);
        processError(error);
      }).finally(() => {
        setStatusMessage(message);
      });
  }

  // POST: creates a audiobook
  async function createAudiobook(bookObj) {
    let url = `${process.env.REACT_APP_API_BASE_URL}/audiobook`;

    const session = await auth.getSession();
    const accessToken = session.accessToken.jwtToken
    let message;

    let headers = {
      "headers": {
        "Authorization": `Bearer ${accessToken}`,
      }
    }
    await axios
      .post(
        url,
        bookObj,
        headers,
      )
      .then((response) => {
        message = {
          status: "success",
          message: "Audiobook was successfully created!"
        };
        getAudiobooks();
      })
      .catch((error) => {
        message = {
          status: "error",
          message: "Audiobook was not successfully created"
        };
        console.log('API createAudiobook() error', bookObj);
        console.log('API error url: ', url);
        processError(error);
      }).finally(() => {
        setStatusMessage(message);
      })
      ;
  }

  // DELETE: deletes a audiobook
  async function deleteAudiobook(audiobookid) {
    let url = `${process.env.REACT_APP_API_BASE_URL}/audiobook/${audiobookid}`;

    const session = await auth.getSession();
    const accessToken = session.accessToken.jwtToken
    let message;

    let headers = {
      "headers": {
        "Authorization": `Bearer ${accessToken}`,
      }
    }
    await axios
      .delete(
        url,
        headers,
      )
      .then((response) => {
        message = {
          status: "success",
          message: "Audiobook was successfully deleted"
        };
        if (selectedAuthor && selectedAuthor.id === authorId) {
          setSelectedAuthor(null);
        }
        getAudiobooks();
      })
      .catch((error) => {
        message = {
          status: "error",
          message: "Audiobook was not successfully deleted"
        };
        console.log('API deleteAudiobook() error', audiobookid);
        console.log('API error url: ', url);
        processError(error);
      }).finally(() => {
        setStatusMessage(message);
      });
  }

  // PUT: updates a books' isCurrent status
  async function setAudiobookIsCurrent(smallBookObj) {
    let url = `${process.env.REACT_APP_API_BASE_URL}/audiobook/status`;

    const session = await auth.getSession();
    const accessToken = session.accessToken.jwtToken

    let message;

    let headers = {
      "headers": {
        "Authorization": `Bearer ${accessToken}`,
      }
    }
    await axios
      .put(
        url,
        smallBookObj,
        headers,
      )
      .then((response) => {
        console.log(response)
        message = {
          status: "success",
          message: "Audiobook was " + (smallBookObj.isCurrent ? "added to" : "removed from") + " current listt!"
        };
        getAudiobooks();
      })
      .catch((error) => {
        message = {
          status: "error",
          message: "Audiobook was not " + (smallBookObj.isCurrent ? "added to" : "removed from" + " current list.")
        };
        console.log('API setAudiobookIsCurrent() error', smallBookObj);
        console.log('API error url: ', url);
        processError(error);
      }).finally(() => {
        setStatusMessage(message);
      });
  }

  // PUT: updates a books' isRead status
  async function setAudiobookIsRead(smallBookObj) {
    let url = `${process.env.REACT_APP_API_BASE_URL}/audiobook/status`;

    const session = await auth.getSession();
    const accessToken = session.accessToken.jwtToken
    let message;
    let headers = {
      "headers": {
        "Authorization": `Bearer ${accessToken}`,
      }
    }
    await axios
      .put(
        url,
        smallBookObj,
        headers,
      )
      .then((response) => {
        message = {
          status: "success",
          message: "Audiobook's status was changed to " + (smallBookObj.isRead ? "'read'!" : "'unread'!")
        };
        getAudiobooks();
      })
      .catch((error) => {
        message = {
          status: "error",
          message: "Audiobook's status was not changed'"
        };
        console.log('API setAudiobookIsRead() error', smallBookObj);
        console.log('API error url: ', url);
        processError(error);
      }).finally(() => {
        console.log("app setAudiobookIsRead status", message)
        setStatusMessage(message);
      })
      ;
  }


  // AUTHOR /////////////////////////
  // GET: list of authors
  async function getAuthorList() {
    let url = `${process.env.REACT_APP_API_BASE_URL}/author`;

    const session = await auth.getSession();
    const accessToken = session.accessToken.jwtToken

    let headers = {
      "headers": {
        "Authorization": `Bearer ${accessToken}`,
      }
    }
    await axios
      .get(
        url,
        headers,
      )
      .then((response) => {
        setAuthorList(response.data);
      })
      .catch((error) => {
        console.log('API getAuthorList() error');
        console.log('API error url: ', url);
        processError(error);
      });
  }

  async function getAuthorSearchList() {
    let url = `${process.env.REACT_APP_API_BASE_URL}/authorcompact`;

    const session = await auth.getSession();
    const accessToken = session.accessToken.jwtToken

    let headers = {
      "headers": {
        "Authorization": `Bearer ${accessToken}`,
      }
    }
    await axios
      .get(
        url,
        headers,
      )
      .then((response) => {
        getAudiobooks();
      })
      .catch((error) => {
        console.log('API getAuthorSearchList() error');
        console.log('API error url: ', url);
        processError(error);
      });
  }


  // GET: a single author
  async function getAuthor(authorId) {
    let url = `${process.env.REACT_APP_API_BASE_URL}/author/${authorId}`;

    const session = await auth.getSession();
    const accessToken = session.accessToken.jwtToken

    let headers = {
      "headers": {
        "Authorization": `Bearer ${accessToken}`,
      }
    }
    await axios
      .get(
        url,
        headers,
      )
      .then((response) => {
        setAuthor(response.data);
      })
      .catch((error) => {
        console.log('API getAuthor() error', authorId);
        console.log('API error url: ', url);
        processError(error);
      });
  }

  // POST: create an author
  async function createAuthor(authorObj) {
    let url = `${process.env.REACT_APP_API_BASE_URL}/author`;

    const session = await auth.getSession();
    const accessToken = session.accessToken.jwtToken

    let headers = {
      "headers": {
        "Authorization": `Bearer ${accessToken}`,
      }
    }
    await axios
      .post(
        url,
        authorObj,
        headers,
      )
      .then((response) => {
        getAuthorList();
      })
      .catch((error) => {
        console.log('API createAuthor() error', authorObj);
        console.log('API error url: ', url);
        processError(error);
      });
  }

  // PUT: update an author
  async function updateAuthor(authorObj) {
    let url = `${process.env.REACT_APP_API_BASE_URL}/author`;

    const session = await auth.getSession();
    const accessToken = session.accessToken.jwtToken

    let headers = {
      "headers": {
        "Authorization": `Bearer ${accessToken}`,
      }
    }
    await axios
      .put(
        url,
        authorObj,
        headers,
      )
      .then((response) => {
        getAuthorList();
        if (authorObj.authorId === selectedAuthor.id) {
          setSelectedAuthor({
            id: selectedAuthor.id,
            label: authorObj.last + ', ' + authorObj.first,
            books: authorObj.bookCount > 0
          })
          setStatusMessage({
            status: "success",
            message: "Author was successfully updated!"
          })
        }
      })
      .catch((error) => {
        setStatusMessage({
          status: "error",
          message: "Author was not updated"
        })
        console.log('API updateAuthor() error', authorObj);
        console.log('API error url: ', url);
        processError(error);
      });
  }


  // DELETE: delete an author
  async function deleteAuthor(authorId) {
    let url = `${process.env.REACT_APP_API_BASE_URL}/author/${authorId}`;

    const session = await auth.getSession();
    const accessToken = session.accessToken.jwtToken

    let headers = {
      "headers": {
        "Authorization": `Bearer ${accessToken}`,
      }
    }
    await axios
      .delete(
        url,
        headers,
      )
      .then((response) => {
        getAuthorList();
      })
      .catch((error) => {
        console.log('API deleteAuthor() error', authorId);
        console.log('API error url: ', url);
        processError(error);
      });
  }


  // ERROR HANDLER /////////////

  function processError(error) {
    if (error.response) {
      // The request was made and the server responded with a status code > 200
      console.log('API error.response.data: ', error.response.data);
      console.log('API error.response.status: ', error.response.status);
      console.log('API error.response.headers: ', error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      console.log('API error.request: ', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('API error.message: ', error.message);
    }
    console.log('API error.config', error.config);
  }

  return (
    <HomeContext.Provider value={homeContext} >
      <AuthorContext.Provider value={authorContext} >
        <AuthorDialogContext.Provider value={authorDialogContext} >
          <AudiobookDialogContext.Provider value={audiobookDialogContext} >
            <AudiobookCardContext.Provider value={audiobookCardContext} >
              <AppbarContext.Provider value={appbarContext} >
                <Index
                  returnPath={returnPath}
                />
              </AppbarContext.Provider>
            </AudiobookCardContext.Provider>
          </AudiobookDialogContext.Provider>
        </AuthorDialogContext.Provider>
      </AuthorContext.Provider>
    </HomeContext.Provider>
  );

}